var myMixin = {
    data(){
        return{
            layout: 'inline',//搜索框类型
            labelcol:{//搜索框尺寸
                md:{span: 8},
                sm:{span: 8}
            },
            wrappercol:{
                md:{span: 16},
                sm:{span: 16}
            },
            data: [],//数据
            selectedRowKeys: [],//选择的表格
            pagination: {
              showQuickJumper:true,//是否快速跳转某页
              showSizeChanger:true,//是否可以改变条数
              pageSizeOptions:['10', '20', '50', '100','200'],
              showTotal:(total, range) => `当前显示第${range[0]}-${range[1]}，总条数：${total} `
            },//分页器
            loading: false,//是否加载中
            tableSize:'default',//表格尺寸
        }
    },
    created: function () {

    },
    methods: {
        onSelectChange(selectedRowKeys) {//选择表格
            this.selectedRowKeys = selectedRowKeys;
          },

        start() {//下载
            console.log("导出",this.selectedRowKeys)
            // ajax request after empty completing
            setTimeout(() => {
                this.selectedRowKeys = [];
            }, 1000);
        },
        search(){//搜索
            console.log(this.form)
            this.data = [];
            this.pagination.current =1;
            this.form.page=1;
            this.fetch();
          },
          reset(formName){
              this.data = [];
            this.$refs[formName].resetFields();
            this.pagination.current =1;
            this.form.page=1
            this.fetch();
          },


          handleTableChange(pagination, filters, sorter) {
            //pagination:分页信息，filters：筛选信息，sorter：排序信息
            this.pagination = { ...pagination };
            this.form.page_size=pagination.pageSize
            this.form.page=pagination.current
            console.log(this.pagination,pagination)

            this.fetch({

            });
          },
    }
  }

  export{myMixin}
