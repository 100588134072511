<template>
    <div>
      <div class="mainContent" style="display: flex;justify-content: flex-end;">
        <a-button type="primary" @click="onAddUser">
          添加用户
        </a-button>
      </div>
      <div class="mainContent" >
        <a-table
          :size="tableSize"
          :scroll="{ x: true }"
          :row-selection="{ selectedRowKeys: selectedRowKeys, onChange: onSelectChange }"
          :columns="columns"
          :row-key="record => record.id"
          :data-source="data"
          :pagination="pagination"
          :loading="loading"
          bordered
          @change="handleTableChange"
        >
        <!-- 角色单元格 -->
        <template slot="deleteStatus" slot-scope="data,record">
              {{record.is_delete|statusFilter}}
        </template>
          <!-- 操作单元格 -->
          <template slot="operation" slot-scope="data">
  
                <span>
    
                  <a-popconfirm v-if="data.is_delete=='0'" title="是否禁用此账号?" @confirm="() => onDisableUser(data)">
                    <a-button size="small" type="danger">禁用</a-button>
                  </a-popconfirm>
                  <a-popconfirm v-else title="是否启用此账号?" @confirm="() => onEnableUser(data)">
                    <a-button size="small" type="primary" class="greenBtn">启用</a-button>
                  </a-popconfirm>
  
                  <a-button size="small" type="primary"  @click="onChangePassword(data)">编辑</a-button>
  
                </span>
  
          </template>
        </a-table>
      </div>
  
    <!-- 修改密码模态框 -->
    <a-modal
  
      title="修改密码"
      :visible="changePasswordModalVisible"
      :confirm-loading="changePasswordConfirmLoading"
      @ok="savePassword"
      @cancel="cancelChangePassword"
    >
  
      <a-form-model
        ref="changePassword"
        :model="changePasswordForm"
        :rules="changePasswordFormRules"
        :label-col="{span:7}"
        :wrapper-col="{span:17}"
      >
        <a-form-model-item label="新密码" prop="password">
          <a-input v-model="changePasswordForm.password" />
        </a-form-model-item>
        <a-form-model-item label="确认密码" prop="password1">
          <a-input v-model="changePasswordForm.password1" />
        </a-form-model-item>
        <a-form-model-item label="TG密钥" prop="tg_key">
          <a-input v-model="changePasswordForm.tg_key" />
        </a-form-model-item>
  
      </a-form-model>
    </a-modal>
    <!-- 添加帐号信息模态框 -->
    <a-modal
  
        title="添加"
        :visible="addModalVisible"
        :confirm-loading="addConfirmLoading"
        @ok="add_saveUser"
        @cancel="add_cancelUser"
      >
        <!-- 添加 -->
        <a-form-model
          ref="addUser"
          :model="addUserForm"
          :rules="addUserFormRules"
          :label-col="{span:7}"
          :wrapper-col="{span:17}"
        >
  
          <a-form-model-item label="用户名" prop="username">
            <a-input v-model="addUserForm.username" />
          </a-form-model-item>
          <a-form-model-item label="密码" prop="password">
            <a-input v-model="addUserForm.password" />
          </a-form-model-item>
          <a-form-model-item label="确认密码" prop="password1">
            <a-input v-model="addUserForm.password1" />
          </a-form-model-item>
          <a-form-model-item label="TG密钥" prop="tg_key">
            <a-input v-model="addUserForm.tg_key" />
          </a-form-model-item>
        </a-form-model>
    </a-modal>
    </div>
  </template>
  <script>
  import {getUserList,editUser,addUser,deleteUser,changePassword} from "@/axios/user.js"
  import {myMixin} from "@/static/js/mixin.js"
  import moment from 'moment';
  const columns = [//列描述
    {
      align:'center',
      title: '用户名',
      dataIndex: 'name',
    },  
    {
      align:'center',
      title: 'TG密钥',
      dataIndex: 'tg_key',
    }, 
    {
      align:'center',
      title: '删除',
      dataIndex: 'is_delete',
      scopedSlots: { customRender: 'deleteStatus', },
    },
    {
      align:'center',
      title: '操作',      
      scopedSlots: { customRender: 'operation', },
    },
  
  ];
  export default {
    filters: {  
        statusFilter(status) {
            const statusMap = {
                0: '启用',
                1: '禁用',
            }
            return statusMap[status]  
        }
    },
    mixins: [myMixin],
    data() {
      let validatePass2 = (rule, value, callback) => {
        if (value !== this.addUserForm.password) {
          callback(new Error("两次密码不一致!"));
        } else {
          callback();
        }
      };
      let validatePass3 = (rule, value, callback) => {
        if (value !== this.changePasswordForm.password) {
          callback(new Error("两次密码不一致!"));
        } else {
          callback();
        }
      };
      return {
        columns,//列描述
  
  
        confirmLoading:false,//编辑模态框
  
        addConfirmLoading:false,//添加模态框
        addModalVisible:false,
        addUserForm:{
          username:'',
          password:'',
          password1:'',
          tg_key:'',
        },
        addUserFormRules: {
          username: [
            { required: true, message: '请输入用户名', trigger: 'blur' },
          ],
  
          password: [
            { required: true, message: '请输入密码', trigger: 'blur' },
            { message: '密码长度为6-18', trigger: 'blur',min:6,max:18 },
          ],
          password1: [
            { validator:validatePass2, required: true,trigger: 'blur' },
          ],
        },
  
        changePasswordConfirmLoading:false,//修改密码模态框
        changePasswordModalVisible:false,
        changePasswordForm:{
          user_id:'',
          password:'',
          password1:'',
          tg_key:'',
  
        },
        changePasswordFormRules: {//修改密码表单验证规则
          password: [
            { required: true, message: '请选择角色', trigger: 'blur' },
            { message: '密码长度为6-18', trigger: 'blur',min:6,max:18 },
          ],
          password1: [
            { validator:validatePass3, required: true,trigger: 'blur' },
          ],
  
        },
      }
  
    },
    created(){
      this.fetch();      
    },
    mounted() {
    },
    methods:{
        async fetch (params = {}) {
          this.loading = true;
          let data=await getUserList({...params,...this.form})
          this.loading = false;
          console.log(data,'data')
  
          this.data = data.data;
        },
        //禁用账号
        async onDisableUser(data){
          data.is_delete=1
          let resule=await editUser({...data,user_id:data.id});
            if(resule.status==1){
              this.$message.success(resule.errmsg);
              this.fetch()
            }else{
              this.$message.error(resule.errmsg);
          }
        },
        //启用账号
        async onEnableUser(data){
          data.is_delete=0
          let resule=await editUser({...data,user_id:data.id});
            if(resule.status==1){
              this.$message.success(resule.errmsg);
              this.fetch()
            }else{
              this.$message.error(resule.errmsg);
          }
        },
  
        //修改密码
        async onChangePassword(data){
          this.changePasswordForm.user_id=data.id
          this.changePasswordModalVisible=true
        },
        //保存密码
        async savePassword(data){
          this.$refs.changePassword.validate( async valid => {
            if (valid) {
              this.changePasswordConfirmLoading = true;
              let resule=await changePassword(this.changePasswordForm);
              if(resule.status==1){
                console.log(resule)
                this.$message.success(resule.errmsg);
                this.changePasswordModalVisible = false;
                this.changePasswordConfirmLoading = false;
                this.$refs.changePassword.resetFields();
                this.fetch();
              }else{
                this.$message.error(resule.errmsg);
                this.changePasswordConfirmLoading = false;
              }
  
            } else {
              return false;
            }
  
          })
        },
        cancelChangePassword(){
          this.$refs.changePassword.resetFields();
          this.changePasswordModalVisible = false;
        },
  
        //添加用户数据
        onAddUser(){
          this.addModalVisible=true
        },
        add_saveUser(){
          this.$refs.addUser.validate( async valid => {
            if (valid) {
              this.addConfirmLoading = true;
  
              let result=await addUser(this.addUserForm);
              if(result.status==1){
                console.log(result)
                this.$message.success("添加成功");
                this.addModalVisible = false;
  
                this.$refs.addUser.resetFields();
                this.fetch();
              }else{
                this.$message.error(result.msg);  
              }
              this.addConfirmLoading = false;
            } else {
              return false;
            }
          });
  
  
        },
        add_cancelUser(){
          this.$refs.addUser.resetFields();
          this.addModalVisible = false;
        },
  
        saveUser(){
          this.$refs.editUser.validate( async valid => {
          if (valid) {
            this.confirmLoading = true;
            let resule=await editUser({...this.editUserForm});
            if(resule.status==1){
              console.log(resule)
              this.$message.success(resule.errmsg);
              this.confirmLoading = false;
              this.$refs.editUser.resetFields();
              this.fetch();
            }else{
              this.$message.error(resule.errmsg);
              this.confirmLoading = false;
            }
  
          } else {
            return false;
          }
        });
  
  
        },
        cancelUser(){
          this.$refs.editUser.resetFields();
        },
      }
  };
  </script>
  <style lang="scss" scoped>
  @import "@/static/css/index.scss";
  .ant-table td { white-space: nowrap; }
  .ant-table td .greenBtn{background-color:#7ac141;border-color:#7ac141;}
  </style>
  